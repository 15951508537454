<template>
	<div>
		<div class="banner">
			<div class="ba_contentDiv">
				<h3 class="ba_cd_title">
					Layout设计
					<span class="ba_cd_ti_text">解决方案</span>
				</h3>
				
				<p class="ba_cd_text">Layout Create a new system design solution</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.banner{
			padding: 40px 200px;
			height: 364px;
			display: flex;
			align-items: center;
			background: url(../../../../assets/img/layout-bannerBg.jpg) center no-repeat #000;
			
			.ba_contentDiv{
				text-align: center;
				width: 100%;
				
				.ba_cd_title{
					height: 50px;
					font-size: 48px;
					line-height: 48px;
					color: #fff;
					font-weight: normal;
					margin-bottom: 20px;
					
					.ba_cd_ti_text{
						color: #ee100c;
					}
				}
				
				.ba_cd_text{
					font-size: 18px;
					margin-top: 20px;
					text-transform: Uppercase;
					color: #fff;
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.banner{
			padding: 40px 200px;
			height: 364px;
			display: flex;
			align-items: center;
			background: url(../../../../assets/img/layout-bannerBg.jpg) center no-repeat #000;
			
			.ba_contentDiv{
				text-align: center;
				width: 100%;
				
				.ba_cd_title{
					height: 50px;
					font-size: 48px;
					line-height: 48px;
					color: #fff;
					font-weight: normal;
					margin-bottom: 20px;
					
					.ba_cd_ti_text{
						color: #ee100c;
					}
				}
				
				.ba_cd_text{
					font-size: 18px;
					margin-top: 20px;
					text-transform: Uppercase;
					color: #fff;
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.banner{
			padding: 40px 140px;
			height: 364px;
			display: flex;
			align-items: center;
			background: url(../../../../assets/img/layout-bannerBg.jpg) center no-repeat #000;
			
			.ba_contentDiv{
				text-align: center;
				width: 100%;
				
				.ba_cd_title{
					height: 50px;
					font-size: 48px;
					line-height: 48px;
					color: #fff;
					font-weight: normal;
					margin-bottom: 20px;
					
					.ba_cd_ti_text{
						color: #ee100c;
					}
				}
				
				.ba_cd_text{
					font-size: 18px;
					margin-top: 20px;
					text-transform: Uppercase;
					color: #fff;
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.banner{
			padding: 40px 30px;
			height: 364px;
			display: flex;
			align-items: center;
			background: url(../../../../assets/img/layout-bannerBg.jpg) center no-repeat #000;
			
			.ba_contentDiv{
				text-align: center;
				width: 100%;
				
				.ba_cd_title{
					height: 50px;
					font-size: 48px;
					line-height: 48px;
					color: #fff;
					font-weight: normal;
					margin-bottom: 20px;
					
					.ba_cd_ti_text{
						color: #ee100c;
					}
				}
				
				.ba_cd_text{
					font-size: 18px;
					margin-top: 20px;
					text-transform: Uppercase;
					color: #fff;
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.banner{
			padding: 100px 30px 40px 30px;
			height: 364px;
			display: flex;
			align-items: center;
			background: url(../../../../assets/img/layout-bannerBg.jpg) center no-repeat #000;
			
			.ba_contentDiv{
				text-align: center;
				width: 100%;
				
				.ba_cd_title{
					height: 50px;
					font-size: 48px;
					line-height: 48px;
					color: #fff;
					font-weight: normal;
					margin-bottom: 20px;
					
					.ba_cd_ti_text{
						color: #ee100c;
					}
				}
				
				.ba_cd_text{
					font-size: 18px;
					margin-top: 20px;
					text-transform: Uppercase;
					color: #fff;
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.banner{
			padding: 100px 30px 40px 30px;
			height: 364px;
			display: flex;
			align-items: center;
			background: url(../../../../assets/img/layout-bannerBg.jpg) center no-repeat #000;
			
			.ba_contentDiv{
				text-align: center;
				width: 100%;
				
				.ba_cd_title{
					height: 50px;
					font-size: 21px;
					line-height: 21px;
					color: #fff;
					font-weight: normal;
					margin-bottom: 20px;
					
					.ba_cd_ti_text{
						color: #ee100c;
					}
				}
				
				.ba_cd_text{
					font-size: 18px;
					margin-top: 20px;
					text-transform: Uppercase;
					color: #fff;
				}
			}
		}
	}
	
</style>