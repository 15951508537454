<template>
	<div>
		<div class="ourAdvantages" v-if="windowWidth>=1200">
			<div class="oa_title">{{$t('solution.layoutDesign.ourAdvantages.title')}}</div>
			
			<div class="oa_tl_label">
				<div class="oa_tl_lb_div">
					<img class="oa_tl_lb_di_img" src="../../../../assets/img/icon/adva1.png"/>
					<div class="oa_tl_lb_di_text">
						{{$t('solution.layoutDesign.ourAdvantages.label1')}}
					</div>
				</div>
				
				<div class="oa_tl_lb_div">
					<img class="oa_tl_lb_di_img" src="../../../../assets/img/icon/adva2.png"/>
					<div class="oa_tl_lb_di_text">
						{{$t('solution.layoutDesign.ourAdvantages.label2')}}
					</div>
				</div>
				
				<div class="oa_tl_lb_div">
					<img class="oa_tl_lb_di_img" src="../../../../assets/img/icon/adva3.png"/>
					<div class="oa_tl_lb_di_text">
						{{$t('solution.layoutDesign.ourAdvantages.label3')}}
					</div>
				</div>
				
				<div class="oa_tl_lb_div">
					<img class="oa_tl_lb_di_img" src="../../../../assets/img/icon/adva4.png"/>
					<div class="oa_tl_lb_di_text">
						{{$t('solution.layoutDesign.ourAdvantages.label4')}}
					</div>
				</div>
			</div>
			
			<div class="oa_tl_content">
				<div class="oa_tl_ct_left">
					<div class="oa_tl_ct_lf_title">{{$t('solution.layoutDesign.ourAdvantages.leftContent.title')}}</div>
					<div class="oa_tl_ct_lf_content">
						<div class="oa_tl_ct_lf_ct_div">
							<img class="oa_tl_ct_lf_ct_di_img" src="../../../../assets/img/icon/software1.png" />
                            <div class="oa_tl_ct_lf_ct_di_text">Cadence Allegro</div>
						</div>
						<div class="oa_tl_ct_lf_ct_div">
							<img class="oa_tl_ct_lf_ct_di_img" src="../../../../assets/img/icon/software2.png" />
						    <div class="oa_tl_ct_lf_ct_di_text">Mentor PADS</div>
						</div>
						<div class="oa_tl_ct_lf_ct_div">
							<img class="oa_tl_ct_lf_ct_di_img" src="../../../../assets/img/icon/software3.png" />
						    <div class="oa_tl_ct_lf_ct_di_text">Altium Designer</div>
						</div>
						<div class="oa_tl_ct_lf_ct_div">
							<img class="oa_tl_ct_lf_ct_di_img" src="../../../../assets/img/icon/software4.png" />
						    <div class="oa_tl_ct_lf_ct_di_text">Protel</div>
						</div>
						<div class="oa_tl_ct_lf_ct_div">
							<img class="oa_tl_ct_lf_ct_di_img" src="../../../../assets/img/icon/software5.png" />
						    <div class="oa_tl_ct_lf_ct_di_text">AutoCAD</div>
						</div>
					</div>
				</div>
				
				<div class="oa_tl_ct_right">
					<div class="oa_tl_ct_rg_title">{{$t('solution.layoutDesign.ourAdvantages.rightContent.title')}}</div>
					<div class="oa_tl_ct_rg_listDiv">
						<div class="oa_tl_ct_rg_ld_div" v-for="(item,index) in $t('solution.layoutDesign.ourAdvantages.rightContent.list')" :key="index">
							<div class="oa_tl_ct_rg_ld_di_name">{{item.name}}</div>
							<div class="oa_tl_ct_rg_ld_di_text">{{item.text}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="ourAdvantages2" v-if="windowWidth<=1199">
			<div class="oa_title">{{$t('solution.layoutDesign.ourAdvantages.title')}}</div>
			
			<div class="oa_tl_label">
				<div class="oa_tl_lb_div">
					<img class="oa_tl_lb_di_img" src="../../../../assets/img/icon/adva1.png"/>
					<div class="oa_tl_lb_di_text">
						{{$t('solution.layoutDesign.ourAdvantages.label1')}}
					</div>
				</div>
				
				<div class="oa_tl_lb_div">
					<img class="oa_tl_lb_di_img" src="../../../../assets/img/icon/adva2.png"/>
					<div class="oa_tl_lb_di_text">
						{{$t('solution.layoutDesign.ourAdvantages.label2')}}
					</div>
				</div>
				
				<div class="oa_tl_lb_div">
					<img class="oa_tl_lb_di_img" src="../../../../assets/img/icon/adva3.png"/>
					<div class="oa_tl_lb_di_text">
						{{$t('solution.layoutDesign.ourAdvantages.label3')}}
					</div>
				</div>
				
				<div class="oa_tl_lb_div">
					<img class="oa_tl_lb_di_img" src="../../../../assets/img/icon/adva4.png"/>
					<div class="oa_tl_lb_di_text">
						{{$t('solution.layoutDesign.ourAdvantages.label4')}}
					</div>
				</div>
			</div>
			
			<div class="oa_tl_content">
				<div class="oa_tl_ct_left">
					<div class="oa_tl_ct_lf_title">{{$t('solution.layoutDesign.ourAdvantages.leftContent.title')}}</div>
					<div class="oa_tl_ct_lf_content">
						<div class="oa_tl_ct_lf_ct_div">
							<img class="oa_tl_ct_lf_ct_di_img" src="../../../../assets/img/icon/software1.png" />
		                    <div class="oa_tl_ct_lf_ct_di_text">Cadence Allegro</div>
						</div>
						<div class="oa_tl_ct_lf_ct_div">
							<img class="oa_tl_ct_lf_ct_di_img" src="../../../../assets/img/icon/software2.png" />
						    <div class="oa_tl_ct_lf_ct_di_text">Mentor PADS</div>
						</div>
						<div class="oa_tl_ct_lf_ct_div">
							<img class="oa_tl_ct_lf_ct_di_img" src="../../../../assets/img/icon/software3.png" />
						    <div class="oa_tl_ct_lf_ct_di_text">Altium Designer</div>
						</div>
						<div class="oa_tl_ct_lf_ct_div">
							<img class="oa_tl_ct_lf_ct_di_img" src="../../../../assets/img/icon/software4.png" />
						    <div class="oa_tl_ct_lf_ct_di_text">Protel</div>
						</div>
						<div class="oa_tl_ct_lf_ct_div">
							<img class="oa_tl_ct_lf_ct_di_img" src="../../../../assets/img/icon/software5.png" />
						    <div class="oa_tl_ct_lf_ct_di_text">AutoCAD</div>
						</div>
					</div>
				</div>
				
				<div class="oa_tl_ct_right">
					<div class="oa_tl_ct_rg_title">{{$t('solution.layoutDesign.ourAdvantages.rightContent.title')}}</div>
					<div class="oa_tl_ct_rg_listDiv">
						<div class="oa_tl_ct_rg_ld_div" v-for="(item,index) in $t('solution.layoutDesign.ourAdvantages.rightContent.list')" :key="index">
							<div class="oa_tl_ct_rg_ld_di_name">{{item.name}}</div>
							<div class="oa_tl_ct_rg_ld_di_text">{{item.text}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				list:[{
					name:'层数可达',
					text:'40层'
				},
				{
					name:'线宽',
					text:'≥2mil（HDI）'
				},
				{
					name:'PIN数',
					text:'≤60000'
				},
				{
					name:'线距',
					text:'≥2mil（HDI）'
				},
				{
					name:'BGA间距',
					text:'≥0.20mm'
				},
				{
					name:'BGA-PIN数',
					text:'≤2500'
				},
				{
					name:'速讯号',
					text:'≤12G(差分讯号)'
				},
				{
					name:'价格HDI',
					text:'任意层互聊（ELIC）'
				}]
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.ourAdvantages{
			padding: 40px 200px;
			
			.oa_title{
				font-size: 32px;
				color: #333333;
				text-align: center;
			}
			
			.oa_tl_label{
				margin-top: 70px;
				width: 100%;
				display: flex;
				
				.oa_tl_lb_div{
					width: 24%;
					display: flex;
					align-items: center;
					margin-right: 1%;
					
					.oa_tl_lb_di_img{
						border: none;
						vertical-align: middle;
						width: 50px;
						height: 50px;
					}
					
					.oa_tl_lb_di_text{
						display: inline-block;
						vertical-align: top;
						line-height: 1.8;
						padding-left: 20px;
						padding-right: 20px;
						font-size: 14px;
						color: #333333;
						
					}
				}
			}
			
			.oa_tl_content{
				margin-top: 50px;
				width: 100%;
				display: flex;
				
				.oa_tl_ct_left{
					width: 40%;
					padding: 20px;
					border-right: 2px solid #ebebeb;
					
					.oa_tl_ct_lf_title{
						height: 60px;
						line-height: 60px;
						border-bottom: 1px solid #ebebeb;
						font-size: 16px;
						color: #444;
					}
					
					.oa_tl_ct_lf_content{
						display: flex;
						width: 100%;
						flex-wrap: wrap;
						
						.oa_tl_ct_lf_ct_div{
							width: 49%;
							margin-right: 1%;
							font-weight: bold;
							font-size: 16px;
							color: #333333;
							align-items: center;
							display: flex;
							margin-top: 10px;
							
							.oa_tl_ct_lf_ct_di_img{
								width: 50px;
								height: 50px;
							}
							
							.oa_tl_ct_lf_ct_di_text{
								padding-left: 10px;
							}
						}
					}
				}
				
				.oa_tl_ct_right{
					width: 60%;
					padding: 20px;
					
					.oa_tl_ct_rg_title{
						height: 60px;
						line-height: 60px;
						font-size: 16px;
						color: #444;
					}
					
					.oa_tl_ct_rg_listDiv{
						display: flex;
						flex-wrap: wrap;
						
						.oa_tl_ct_rg_ld_div{
							width: 49%;
							margin-right: 1%;
							background: #f5f5f5;
							padding: 0 18px;
							margin-top: 14px;
							border-radius: 4px;
							box-sizing: border-box;
							font-size: 14px;
							color: #333333;
							display: flex;
							padding: 16px;
							
							.oa_tl_ct_rg_ld_di_name{
								width: 50%;
							}
							
							.oa_tl_ct_rg_ld_di_text{
								width: 50%;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.ourAdvantages{
			padding: 40px 200px;
			
			.oa_title{
				font-size: 32px;
				color: #333333;
				text-align: center;
			}
			
			.oa_tl_label{
				margin-top: 70px;
				width: 100%;
				display: flex;
				
				.oa_tl_lb_div{
					width: 24%;
					display: flex;
					align-items: center;
					margin-right: 1%;
					
					.oa_tl_lb_di_img{
						border: none;
						vertical-align: middle;
						width: 50px;
						height: 50px;
					}
					
					.oa_tl_lb_di_text{
						display: inline-block;
						vertical-align: top;
						line-height: 1.8;
						padding-left: 20px;
						padding-right: 20px;
						font-size: 14px;
						color: #333333;
						
					}
				}
			}
			
			.oa_tl_content{
				margin-top: 50px;
				width: 100%;
				display: flex;
				
				.oa_tl_ct_left{
					width: 40%;
					padding: 20px;
					border-right: 2px solid #ebebeb;
					
					.oa_tl_ct_lf_title{
						height: 60px;
						line-height: 60px;
						border-bottom: 1px solid #ebebeb;
						font-size: 16px;
						color: #444;
					}
					
					.oa_tl_ct_lf_content{
						display: flex;
						width: 100%;
						flex-wrap: wrap;
						
						.oa_tl_ct_lf_ct_div{
							width: 49%;
							margin-right: 1%;
							font-weight: bold;
							font-size: 16px;
							color: #333333;
							align-items: center;
							display: flex;
							margin-top: 10px;
							
							.oa_tl_ct_lf_ct_di_img{
								width: 50px;
								height: 50px;
							}
							
							.oa_tl_ct_lf_ct_di_text{
								padding-left: 10px;
							}
						}
					}
				}
				
				.oa_tl_ct_right{
					width: 60%;
					padding: 20px;
					
					.oa_tl_ct_rg_title{
						height: 60px;
						line-height: 60px;
						font-size: 16px;
						color: #444;
					}
					
					.oa_tl_ct_rg_listDiv{
						display: flex;
						flex-wrap: wrap;
						
						.oa_tl_ct_rg_ld_div{
							width: 49%;
							margin-right: 1%;
							background: #f5f5f5;
							padding: 0 18px;
							margin-top: 14px;
							border-radius: 4px;
							box-sizing: border-box;
							font-size: 14px;
							color: #333333;
							display: flex;
							padding: 16px;
							
							.oa_tl_ct_rg_ld_di_name{
								width: 50%;
							}
							
							.oa_tl_ct_rg_ld_di_text{
								width: 50%;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.ourAdvantages{
			padding: 40px 140px;
			
			.oa_title{
				font-size: 32px;
				color: #333333;
				text-align: center;
			}
			
			.oa_tl_label{
				margin-top: 70px;
				width: 100%;
				display: flex;
				
				.oa_tl_lb_div{
					width: 24%;
					display: flex;
					align-items: center;
					margin-right: 1%;
					
					.oa_tl_lb_di_img{
						border: none;
						vertical-align: middle;
						width: 50px;
						height: 50px;
					}
					
					.oa_tl_lb_di_text{
						display: inline-block;
						vertical-align: top;
						line-height: 1.8;
						padding-left: 20px;
						padding-right: 20px;
						font-size: 14px;
						color: #333333;
						
					}
				}
			}
			
			.oa_tl_content{
				margin-top: 50px;
				width: 100%;
				display: flex;
				
				.oa_tl_ct_left{
					width: 40%;
					padding: 20px;
					border-right: 2px solid #ebebeb;
					
					.oa_tl_ct_lf_title{
						height: 60px;
						line-height: 60px;
						border-bottom: 1px solid #ebebeb;
						font-size: 16px;
						color: #444;
					}
					
					.oa_tl_ct_lf_content{
						display: flex;
						width: 100%;
						flex-wrap: wrap;
						
						.oa_tl_ct_lf_ct_div{
							width: 49%;
							margin-right: 1%;
							font-weight: bold;
							font-size: 16px;
							color: #333333;
							align-items: center;
							display: flex;
							margin-top: 10px;
							
							.oa_tl_ct_lf_ct_di_img{
								width: 50px;
								height: 50px;
							}
							
							.oa_tl_ct_lf_ct_di_text{
								padding-left: 10px;
							}
						}
					}
				}
				
				.oa_tl_ct_right{
					width: 60%;
					padding: 20px;
					
					.oa_tl_ct_rg_title{
						height: 60px;
						line-height: 60px;
						font-size: 16px;
						color: #444;
					}
					
					.oa_tl_ct_rg_listDiv{
						display: flex;
						flex-wrap: wrap;
						
						.oa_tl_ct_rg_ld_div{
							width: 49%;
							margin-right: 1%;
							background: #f5f5f5;
							padding: 0 18px;
							margin-top: 14px;
							border-radius: 4px;
							box-sizing: border-box;
							font-size: 14px;
							color: #333333;
							display: flex;
							padding: 16px;
							
							.oa_tl_ct_rg_ld_di_name{
								width: 50%;
							}
							
							.oa_tl_ct_rg_ld_di_text{
								width: 50%;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.ourAdvantages{
			padding: 40px 30px;
			
			.oa_title{
				font-size: 32px;
				color: #333333;
				text-align: center;
			}
			
			.oa_tl_label{
				margin-top: 70px;
				width: 100%;
				display: flex;
				
				.oa_tl_lb_div{
					width: 24%;
					display: flex;
					align-items: center;
					margin-right: 1%;
					
					.oa_tl_lb_di_img{
						border: none;
						vertical-align: middle;
						width: 50px;
						height: 50px;
					}
					
					.oa_tl_lb_di_text{
						display: inline-block;
						vertical-align: top;
						line-height: 1.8;
						padding-left: 20px;
						padding-right: 20px;
						font-size: 14px;
						color: #333333;
						
					}
				}
			}
			
			.oa_tl_content{
				margin-top: 50px;
				width: 100%;
				display: flex;
				
				.oa_tl_ct_left{
					width: 40%;
					padding: 20px;
					border-right: 2px solid #ebebeb;
					
					.oa_tl_ct_lf_title{
						height: 60px;
						line-height: 60px;
						border-bottom: 1px solid #ebebeb;
						font-size: 16px;
						color: #444;
					}
					
					.oa_tl_ct_lf_content{
						display: flex;
						width: 100%;
						flex-wrap: wrap;
						
						.oa_tl_ct_lf_ct_div{
							width: 49%;
							margin-right: 1%;
							font-weight: bold;
							font-size: 16px;
							color: #333333;
							align-items: center;
							display: flex;
							margin-top: 10px;
							
							.oa_tl_ct_lf_ct_di_img{
								width: 50px;
								height: 50px;
							}
							
							.oa_tl_ct_lf_ct_di_text{
								padding-left: 10px;
							}
						}
					}
				}
				
				.oa_tl_ct_right{
					width: 60%;
					padding: 20px;
					
					.oa_tl_ct_rg_title{
						height: 60px;
						line-height: 60px;
						font-size: 16px;
						color: #444;
					}
					
					.oa_tl_ct_rg_listDiv{
						display: flex;
						flex-wrap: wrap;
						
						.oa_tl_ct_rg_ld_div{
							width: 49%;
							margin-right: 1%;
							background: #f5f5f5;
							padding: 0 18px;
							margin-top: 14px;
							border-radius: 4px;
							box-sizing: border-box;
							font-size: 14px;
							color: #333333;
							display: flex;
							padding: 16px;
							
							.oa_tl_ct_rg_ld_di_name{
								width: 50%;
							}
							
							.oa_tl_ct_rg_ld_di_text{
								width: 50%;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.ourAdvantages2{
			padding: 40px 30px;
			
			.oa_title{
				font-size: 32px;
				color: #333333;
				text-align: center;
			}
			
			.oa_tl_label{
				margin-top: 70px;
				width: 100%;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				
				.oa_tl_lb_div{
					width: 48%;
					display: flex;
					align-items: center;
					margin-right: 2%;
					margin-bottom: 40px;
					
					.oa_tl_lb_di_img{
						border: none;
						vertical-align: middle;
						width: 40px;
						height: 40px;
					}
					
					.oa_tl_lb_di_text{
						display: inline-block;
						vertical-align: top;
						line-height: 1.8;
						padding-left: 20px;
						padding-right: 20px;
						font-size: 14px;
						color: #333333;
						
					}
				}
			}
			
			.oa_tl_content{
				width: 100%;
				
				.oa_tl_ct_left{
					width: 100%;
					padding: 20px;
					
					.oa_tl_ct_lf_title{
						height: 60px;
						line-height: 60px;
						font-size: 16px;
						color: #444;
					}
					
					.oa_tl_ct_lf_content{
						display: flex;
						width: 100%;
						flex-wrap: wrap;
						
						.oa_tl_ct_lf_ct_div{
							width: 49%;
							margin-right: 1%;
							font-weight: bold;
							font-size: 16px;
							color: #333333;
							align-items: center;
							display: flex;
							margin-top: 10px;
							
							.oa_tl_ct_lf_ct_di_img{
								width: 50px;
								height: 50px;
							}
							
							.oa_tl_ct_lf_ct_di_text{
								padding-left: 10px;
							}
						}
					}
				}
				
				.oa_tl_ct_right{
					width: 100%;
					padding: 20px;
					
					.oa_tl_ct_rg_title{
						height: 60px;
						line-height: 60px;
						font-size: 16px;
						color: #444;
					}
					
					.oa_tl_ct_rg_listDiv{
						display: flex;
						flex-wrap: wrap;
						
						.oa_tl_ct_rg_ld_div{
							width: 49%;
							margin-right: 1%;
							background: #f5f5f5;
							padding: 0 18px;
							margin-top: 14px;
							border-radius: 4px;
							box-sizing: border-box;
							font-size: 14px;
							color: #333333;
							display: flex;
							padding: 16px;
							
							.oa_tl_ct_rg_ld_di_name{
								width: 50%;
							}
							
							.oa_tl_ct_rg_ld_di_text{
								width: 50%;
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.ourAdvantages2{
			padding: 40px 30px;
			
			.oa_title{
				font-size: 32px;
				color: #333333;
				text-align: center;
			}
			
			.oa_tl_label{
				margin-top: 70px;
				width: 100%;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				
				.oa_tl_lb_div{
					width: 100%;
					display: flex;
					align-items: center;
					margin-bottom: 40px;
					
					.oa_tl_lb_di_img{
						border: none;
						vertical-align: middle;
						width: 40px;
						height: 40px;
					}
					
					.oa_tl_lb_di_text{
						display: inline-block;
						vertical-align: top;
						line-height: 1.8;
						padding-left: 20px;
						padding-right: 20px;
						font-size: 14px;
						color: #333333;
						
					}
				}
			}
			
			.oa_tl_content{
				width: 100%;
				
				.oa_tl_ct_left{
					width: 100%;
					
					.oa_tl_ct_lf_title{
						height: 60px;
						line-height: 60px;
						font-size: 16px;
						color: #444;
					}
					
					.oa_tl_ct_lf_content{
						display: flex;
						width: 100%;
						flex-wrap: wrap;
						
						.oa_tl_ct_lf_ct_div{
							width: 100%;
							margin-right: 1%;
							font-weight: bold;
							font-size: 16px;
							color: #333333;
							align-items: center;
							display: flex;
							margin-top: 10px;
							
							.oa_tl_ct_lf_ct_di_img{
								width: 50px;
								height: 50px;
							}
							
							.oa_tl_ct_lf_ct_di_text{
								padding-left: 10px;
							}
						}
					}
				}
				
				.oa_tl_ct_right{
					width: 100%;
					
					.oa_tl_ct_rg_title{
						height: 60px;
						line-height: 60px;
						font-size: 16px;
						color: #444;
					}
					
					.oa_tl_ct_rg_listDiv{
						display: flex;
						flex-wrap: wrap;
						
						.oa_tl_ct_rg_ld_div{
							width: 100%;
							margin-right: 1%;
							background: #f5f5f5;
							padding: 0 18px;
							margin-top: 14px;
							border-radius: 4px;
							box-sizing: border-box;
							font-size: 14px;
							color: #333333;
							display: flex;
							padding: 16px;
							
							.oa_tl_ct_rg_ld_di_name{
								width: 50%;
							}
							
							.oa_tl_ct_rg_ld_di_text{
								width: 50%;
							}
						}
					}
				}
			}
		}
	}
	
</style>