<template>
	<div>
		<div class="designShow">
			<div class="ds_title">{{$t('solution.layoutDesign.designShow.title')}}</div>

			<el-carousel class="ds_carousel" :interval="4000" type="card" :height="windowWidth>=1000 ? '500px' : '200px'">
			    <el-carousel-item v-for="(item,index) in list" :key="index">
			      <img style="width: 100%;height: 100%;" :src="item" />
			    </el-carousel-item>
			  </el-carousel>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				list:[process.env.VUE_APP_OSS_URL + '/solution/demo1.png',process.env.VUE_APP_OSS_URL + '/solution/demo2.png',process.env.VUE_APP_OSS_URL + '/solution/demo3.png']
			}
		},

		created() {

		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {

		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.designShow{
			padding: 20px 200px;
			
			.ds_title{
				text-align: center;
				font-size: 32px;
				color: #333333;
			}
			
			.ds_carousel{
				margin-top: 40px;
			}
		}
		
		.el-carousel__item h3 {
		    color: #475669;
		    font-size: 14px;
		    opacity: 0.75;
		    line-height: 200px;
		    margin: 0;
		  }
		  
		  .el-carousel__item:nth-child(2n) {
		    background-color: #99a9bf;
		  }
		  
		  .el-carousel__item:nth-child(2n+1) {
		    background-color: #d3dce6;
		  }
	}
	
	@media only screen and (max-width:1920px) {
		.designShow{
			padding: 20px 200px;
			
			.ds_title{
				text-align: center;
				font-size: 32px;
				color: #333333;
			}
			
			.ds_carousel{
				margin-top: 40px;
			}
		}
		
		.el-carousel__item h3 {
		    color: #475669;
		    font-size: 14px;
		    opacity: 0.75;
		    line-height: 200px;
		    margin: 0;
		  }
		  
		  .el-carousel__item:nth-child(2n) {
		    background-color: #99a9bf;
		  }
		  
		  .el-carousel__item:nth-child(2n+1) {
		    background-color: #d3dce6;
		  }
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.designShow{
			padding: 20px 140px;
			
			.ds_title{
				text-align: center;
				font-size: 32px;
				color: #333333;
			}
			
			.ds_carousel{
				margin-top: 40px;
			}
		}
		
		.el-carousel__item h3 {
		    color: #475669;
		    font-size: 14px;
		    opacity: 0.75;
		    line-height: 200px;
		    margin: 0;
		  }
		  
		  .el-carousel__item:nth-child(2n) {
		    background-color: #99a9bf;
		  }
		  
		  .el-carousel__item:nth-child(2n+1) {
		    background-color: #d3dce6;
		  }
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.designShow{
			padding: 20px 30px;
			
			.ds_title{
				text-align: center;
				font-size: 32px;
				color: #333333;
			}
			
			.ds_carousel{
				margin-top: 40px;
			}
		}
		
		.el-carousel__item h3 {
		    color: #475669;
		    font-size: 14px;
		    opacity: 0.75;
		    line-height: 200px;
		    margin: 0;
		  }
		  
		  .el-carousel__item:nth-child(2n) {
		    background-color: #99a9bf;
		  }
		  
		  .el-carousel__item:nth-child(2n+1) {
		    background-color: #d3dce6;
		  }
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.designShow{
			padding: 20px 30px;
			
			.ds_title{
				text-align: center;
				font-size: 32px;
				color: #333333;
			}
			
			.ds_carousel{
				margin-top: 40px;
			}
		}
		
		.el-carousel__item h3 {
		    color: #475669;
		    font-size: 14px;
		    opacity: 0.75;
		    line-height: 200px;
		    margin: 0;
		  }
		  
		  .el-carousel__item:nth-child(2n) {
		    background-color: #99a9bf;
		  }
		  
		  .el-carousel__item:nth-child(2n+1) {
		    background-color: #d3dce6;
		  }
	}
	
	@media only screen and (max-width:767px) {
		.designShow{
			padding: 20px 30px;
			
			.ds_title{
				text-align: center;
				font-size: 32px;
				color: #333333;
			}
			
			.ds_carousel{
				margin-top: 40px;
			}
		}
		
		.el-carousel__item h3 {
		    color: #475669;
		    font-size: 14px;
		    opacity: 0.75;
		    line-height: 200px;
		    margin: 0;
		  }
		  
		  .el-carousel__item:nth-child(2n) {
		    background-color: #99a9bf;
		  }
		  
		  .el-carousel__item:nth-child(2n+1) {
		    background-color: #d3dce6;
		  }
	}
	
</style>
