<template>
	<div>
		<div class="designCapacity" v-if="windowWidth>=1200">
			<div class="dc_titleDiv">
				<div class="dc_td_title">{{$t('solution.layoutDesign.designCapacity.title')}}</div>
				<p class="dc_td_text">{{$t('solution.layoutDesign.designCapacity.label1')}}&nbsp;|&nbsp;{{$t('solution.layoutDesign.designCapacity.label2')}}&nbsp;|&nbsp;{{$t('solution.layoutDesign.designCapacity.label3')}}&nbsp;|&nbsp;{{$t('solution.layoutDesign.designCapacity.label4')}}</p>
			</div>

			<div class="dc_contentDiv">
				<el-image class="dc_cd_img" :src="$t('solution.layoutDesign.designCapacity.img1')" :fit="'cover'">
				</el-image>

				<div class="dc_cd_labelDiv">
					<div class="dc_cd_ld_div">
						<img class="dc_cd_ld_di_img" :src="$t('solution.layoutDesign.designCapacity.icon1.img')" />
						<div class="dc_cd_ld_di_contentList">
							<div class="dc_cd_ld_di_cl_div">
								<div class="text">{{$t('solution.layoutDesign.designCapacity.icon1.text1')}}</div>
								<div class="label">{{$t('solution.layoutDesign.designCapacity.icon1.label1')}}</div>
							</div>
							<div class="dc_cd_ld_di_cl_div">
								<div class="text">{{$t('solution.layoutDesign.designCapacity.icon1.text2')}}</div>
								<div class="label">{{$t('solution.layoutDesign.designCapacity.icon1.label2')}}</div>
							</div>
						</div>
					</div>

					<div class="dc_cd_ld_div">
						<img class="dc_cd_ld_di_img" :src="$t('solution.layoutDesign.designCapacity.icon2.img')" />
						<div class="dc_cd_ld_di_contentList">
							<div class="dc_cd_ld_di_cl_div">
								<div class="text">{{$t('solution.layoutDesign.designCapacity.icon2.text1')}}</div>
								<div class="label">{{$t('solution.layoutDesign.designCapacity.icon2.label1')}}</div>
							</div>
							<div class="dc_cd_ld_di_cl_div">
								<div class="text">{{$t('solution.layoutDesign.designCapacity.icon2.text2')}}</div>
								<div class="label">{{$t('solution.layoutDesign.designCapacity.icon1.label2')}}</div>
							</div>
						</div>
					</div>

					<div class="dc_cd_ld_div">
						<img class="dc_cd_ld_di_img" :src="$t('solution.layoutDesign.designCapacity.icon3.img')" />
						<div class="dc_cd_ld_di_contentList">
							<div class="dc_cd_ld_di_cl_div">
								<div class="text2">{{$t('solution.layoutDesign.designCapacity.icon3.text1')}}</div>
							</div>
							<div class="dc_cd_ld_di_cl_div">
								<div class="text2">{{$t('solution.layoutDesign.designCapacity.icon3.text2')}}</div>
							</div>
							<div class="dc_cd_ld_di_cl_div">
								<div class="text2">{{$t('solution.layoutDesign.designCapacity.icon3.text3')}}</div>
							</div>
						</div>
					</div>

					<div class="dc_cd_ld_div">
						<img class="dc_cd_ld_di_img" :src="$t('solution.layoutDesign.designCapacity.icon4.img')" />
						<div class="dc_cd_ld_di_contentList">
							<div class="dc_cd_ld_di_cl_div">
								<div class="text2">{{$t('solution.layoutDesign.designCapacity.icon4.text1')}}</div>
							</div>
							<div class="dc_cd_ld_di_cl_div">
								<div class="text2">{{$t('solution.layoutDesign.designCapacity.icon4.text2')}}</div>
							</div>
							<div class="dc_cd_ld_di_cl_div">
								<div class="text2">{{$t('solution.layoutDesign.designCapacity.icon4.text3')}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<!-- <div style="display: flex;justify-content: center;">
				<div class="dc_jump">
					立即下单
				</div>
			</div> -->

			<div class="dc_bottomDiv">
				{{$t('solution.layoutDesign.designCapacity.text')}}
			</div>
		</div>
		
		<div class="designCapacity2" v-if="windowWidth<=1199">
			<div class="dc_titleDiv">
				<div class="dc_td_title">{{$t('solution.layoutDesign.designCapacity.title')}}</div>
				<p class="dc_td_text">{{$t('solution.layoutDesign.designCapacity.label1')}}&nbsp;|&nbsp;{{$t('solution.layoutDesign.designCapacity.label2')}}&nbsp;|&nbsp;{{$t('solution.layoutDesign.designCapacity.label3')}}&nbsp;|&nbsp;{{$t('solution.layoutDesign.designCapacity.label4')}}</p>
			</div>
		
			<div class="dc_contentDiv">
				<el-image class="dc_cd_img" :src="$t('solution.layoutDesign.designCapacity.img1')" :fit="'cover'">
				</el-image>
		
				<div class="dc_cd_labelDiv">
					<div class="dc_cd_ld_div">
						<img class="dc_cd_ld_di_img" :src="$t('solution.layoutDesign.designCapacity.icon1.img')" />
						<div class="dc_cd_ld_di_contentList">
							<div class="dc_cd_ld_di_cl_div">
								<div class="text">{{$t('solution.layoutDesign.designCapacity.icon1.text1')}}</div>
								<div class="label">{{$t('solution.layoutDesign.designCapacity.icon1.label1')}}</div>
							</div>
							<div class="dc_cd_ld_di_cl_div">
								<div class="text">{{$t('solution.layoutDesign.designCapacity.icon1.text2')}}</div>
								<div class="label">{{$t('solution.layoutDesign.designCapacity.icon1.label2')}}</div>
							</div>
						</div>
					</div>
		
					<div class="dc_cd_ld_div">
						<img class="dc_cd_ld_di_img" :src="$t('solution.layoutDesign.designCapacity.icon2.img')" />
						<div class="dc_cd_ld_di_contentList">
							<div class="dc_cd_ld_di_cl_div">
								<div class="text">{{$t('solution.layoutDesign.designCapacity.icon2.text1')}}</div>
								<div class="label">{{$t('solution.layoutDesign.designCapacity.icon2.label1')}}</div>
							</div>
							<div class="dc_cd_ld_di_cl_div">
								<div class="text">{{$t('solution.layoutDesign.designCapacity.icon2.text2')}}</div>
								<div class="label">{{$t('solution.layoutDesign.designCapacity.icon1.label2')}}</div>
							</div>
						</div>
					</div>
		
					<div class="dc_cd_ld_div">
						<img class="dc_cd_ld_di_img" :src="$t('solution.layoutDesign.designCapacity.icon3.img')" />
						<div class="dc_cd_ld_di_contentList">
							<div class="dc_cd_ld_di_cl_div">
								<div class="text2">{{$t('solution.layoutDesign.designCapacity.icon3.text1')}}</div>
							</div>
							<div class="dc_cd_ld_di_cl_div">
								<div class="text2">{{$t('solution.layoutDesign.designCapacity.icon3.text2')}}</div>
							</div>
							<div class="dc_cd_ld_di_cl_div">
								<div class="text2">{{$t('solution.layoutDesign.designCapacity.icon3.text3')}}</div>
							</div>
						</div>
					</div>
		
					<div class="dc_cd_ld_div">
						<img class="dc_cd_ld_di_img" :src="$t('solution.layoutDesign.designCapacity.icon4.img')" />
						<div class="dc_cd_ld_di_contentList">
							<div class="dc_cd_ld_di_cl_div">
								<div class="text2">{{$t('solution.layoutDesign.designCapacity.icon4.text1')}}</div>
							</div>
							<div class="dc_cd_ld_di_cl_div">
								<div class="text2">{{$t('solution.layoutDesign.designCapacity.icon4.text2')}}</div>
							</div>
							<div class="dc_cd_ld_di_cl_div">
								<div class="text2">{{$t('solution.layoutDesign.designCapacity.icon4.text3')}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<!-- <div style="display: flex;justify-content: center;">
				<div class="dc_jump">
					立即下单
				</div>
			</div> -->
		
			<div class="dc_bottomDiv">
				{{$t('solution.layoutDesign.designCapacity.text')}}
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {

			}
		},

		created() {

		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {

		}

	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.designCapacity {
			padding: 40px 200px;
		
			.dc_titleDiv {
				width: 100%;
				text-align: center;
		
				.dc_td_title {
					font-size: 36px;
				}
		
				.dc_td_text {
					color: #666666;
					font-size: 14px;
					margin-top: 10px;
				}
			}
		
			.dc_contentDiv {
				padding-top: 36px;
				padding-bottom: 80px;
				position: relative;
		
				.dc_cd_img {
					z-index: 3;
					width: 100%;
					height: 300px;
				}
		
				.dc_cd_labelDiv {
					width: 100%;
					margin-top: 34px;
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
		
					.dc_cd_ld_div {
						width: 270px;
						display: flex;
						align-items: center;
						margin-right: 40px;
						margin-top: 40px;
		
						.dc_cd_ld_di_img {
							// width: 80px;
							// height: 80px;
						}
		
						.dc_cd_ld_di_contentList {
							color: #333333;
							font-size: 14px;
							padding-left: 18px;
							display: table-cell;
							vertical-align: middle;
		
							.dc_cd_ld_di_cl_div {
								margin-top: 10px;
								display: flex;
		
								.text {
									display: inline-block;
									width: 80px;
								}
		
								.text2 {
									display: inline-block;
									width: 150px;
								}
		
								.label {
									display: inline-block;
									color: #d1102d;
									border-radius: 10px;
									border: 1px solid #d1102d;
									font-size: 12px;
									width: 58px;
									text-align: center;
									height: 18px;
									line-height: 18px;
								}
							}
						}
					}
				}
			}
			
			.dc_jump {
				display: inline-block;
				text-align: center;
				width: 260px;
				height: 46px;
				line-height: 46px;
				color: #ffffff;
				font-size: 16px;
				background-color: #f54e1b;
				border-radius: 30px;
				z-index: 3;
			}
		
			.dc_bottomDiv {
				padding: 80px 200px;
				background: url(../../../../assets/img/ability-bg.png) no-repeat center;
				width: 100%;
				text-align: center;
				color: #fff;
				line-height: 30px;
				font-size: 14px;
				position: relative;
		
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.designCapacity {
			padding: 40px 200px;
		
			.dc_titleDiv {
				width: 100%;
				text-align: center;
		
				.dc_td_title {
					font-size: 36px;
				}
		
				.dc_td_text {
					color: #666666;
					font-size: 14px;
					margin-top: 10px;
				}
			}
		
			.dc_contentDiv {
				padding-top: 36px;
				padding-bottom: 80px;
				position: relative;
		
				.dc_cd_img {
					z-index: 3;
					width: 100%;
					height: 300px;
				}
		
				.dc_cd_labelDiv {
					width: 100%;
					margin-top: 34px;
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
		
					.dc_cd_ld_div {
						width: 270px;
						display: flex;
						align-items: center;
						margin-right: 40px;
						margin-top: 40px;
		
						.dc_cd_ld_di_img {
							// width: 80px;
							// height: 80px;
						}
		
						.dc_cd_ld_di_contentList {
							color: #333333;
							font-size: 14px;
							padding-left: 18px;
							display: table-cell;
							vertical-align: middle;
		
							.dc_cd_ld_di_cl_div {
								margin-top: 10px;
								display: flex;
		
								.text {
									display: inline-block;
									width: 80px;
								}
		
								.text2 {
									display: inline-block;
									width: 150px;
								}
		
								.label {
									display: inline-block;
									color: #d1102d;
									border-radius: 10px;
									border: 1px solid #d1102d;
									font-size: 12px;
									width: 58px;
									text-align: center;
									height: 18px;
									line-height: 18px;
								}
							}
						}
					}
				}
			}
			
			.dc_jump {
				display: inline-block;
				text-align: center;
				width: 260px;
				height: 46px;
				line-height: 46px;
				color: #ffffff;
				font-size: 16px;
				background-color: #f54e1b;
				border-radius: 30px;
				z-index: 3;
			}
		
			.dc_bottomDiv {
				padding: 80px 200px;
				background: url(../../../../assets/img/ability-bg.png) no-repeat center;
				width: 100%;
				text-align: center;
				color: #fff;
				line-height: 30px;
				font-size: 14px;
				position: relative;
		
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.designCapacity {
			padding: 40px 140px;
		
			.dc_titleDiv {
				width: 100%;
				text-align: center;
		
				.dc_td_title {
					font-size: 36px;
				}
		
				.dc_td_text {
					color: #666666;
					font-size: 14px;
					margin-top: 10px;
				}
			}
		
			.dc_contentDiv {
				padding-top: 36px;
				padding-bottom: 80px;
				position: relative;
		
				.dc_cd_img {
					z-index: 3;
					width: 100%;
					height: 300px;
				}
		
				.dc_cd_labelDiv {
					width: 100%;
					margin-top: 34px;
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
		
					.dc_cd_ld_div {
						width: 270px;
						display: flex;
						align-items: center;
						margin-right: 40px;
						margin-top: 40px;
		
						.dc_cd_ld_di_img {
							// width: 80px;
							// height: 80px;
						}
		
						.dc_cd_ld_di_contentList {
							color: #333333;
							font-size: 14px;
							padding-left: 18px;
							display: table-cell;
							vertical-align: middle;
		
							.dc_cd_ld_di_cl_div {
								margin-top: 10px;
								display: flex;
		
								.text {
									display: inline-block;
									width: 80px;
								}
		
								.text2 {
									display: inline-block;
									width: 150px;
								}
		
								.label {
									display: inline-block;
									color: #d1102d;
									border-radius: 10px;
									border: 1px solid #d1102d;
									font-size: 12px;
									width: 58px;
									text-align: center;
									height: 18px;
									line-height: 18px;
								}
							}
						}
					}
				}
			}
			
			.dc_jump {
				display: inline-block;
				text-align: center;
				width: 260px;
				height: 46px;
				line-height: 46px;
				color: #ffffff;
				font-size: 16px;
				background-color: #f54e1b;
				border-radius: 30px;
				z-index: 3;
			}
		
			.dc_bottomDiv {
				padding: 80px 140px;
				background: url(../../../../assets/img/ability-bg.png) no-repeat center;
				width: 100%;
				text-align: center;
				color: #fff;
				line-height: 30px;
				font-size: 14px;
				position: relative;
		
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.designCapacity {
			padding: 40px 30px;
		
			.dc_titleDiv {
				width: 100%;
				text-align: center;
		
				.dc_td_title {
					font-size: 36px;
				}
		
				.dc_td_text {
					color: #666666;
					font-size: 14px;
					margin-top: 10px;
				}
			}
		
			.dc_contentDiv {
				padding-top: 36px;
				padding-bottom: 80px;
				position: relative;
		
				.dc_cd_img {
					z-index: 3;
					width: 100%;
					height: 300px;
				}
		
				.dc_cd_labelDiv {
					width: 100%;
					margin-top: 34px;
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
		
					.dc_cd_ld_div {
						width: 270px;
						display: flex;
						align-items: center;
						margin-right: 40px;
						margin-top: 40px;
		
						.dc_cd_ld_di_img {
							// width: 80px;
							// height: 80px;
						}
		
						.dc_cd_ld_di_contentList {
							color: #333333;
							font-size: 14px;
							padding-left: 18px;
							display: table-cell;
							vertical-align: middle;
		
							.dc_cd_ld_di_cl_div {
								margin-top: 10px;
								display: flex;
		
								.text {
									display: inline-block;
									width: 80px;
								}
		
								.text2 {
									display: inline-block;
									width: 150px;
								}
		
								.label {
									display: inline-block;
									color: #d1102d;
									border-radius: 10px;
									border: 1px solid #d1102d;
									font-size: 12px;
									width: 58px;
									text-align: center;
									height: 18px;
									line-height: 18px;
								}
							}
						}
					}
				}
			}
			
			.dc_jump {
				display: inline-block;
				text-align: center;
				width: 260px;
				height: 46px;
				line-height: 46px;
				color: #ffffff;
				font-size: 16px;
				background-color: #f54e1b;
				border-radius: 30px;
				z-index: 3;
			}
		
			.dc_bottomDiv {
				padding: 80px 30px;
				background: url(../../../../assets/img/ability-bg.png) no-repeat center;
				width: 100%;
				text-align: center;
				color: #fff;
				line-height: 30px;
				font-size: 14px;
				position: relative;
		
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.designCapacity2 {
			padding: 40px 30px;
		
			.dc_titleDiv {
				width: 100%;
				text-align: center;
		
				.dc_td_title {
					font-size: 21px;
				}
		
				.dc_td_text {
					color: #666666;
					font-size: 14px;
					margin-top: 10px;
				}
			}
		
			.dc_contentDiv {
				padding-top: 36px;
				padding-bottom: 80px;
				position: relative;
		
				.dc_cd_img {
					z-index: 3;
					width: 100%;
					height: 300px;
				}
		
				.dc_cd_labelDiv {
					width: 100%;
					margin-top: 34px;
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
		
					.dc_cd_ld_div {
						width: 270px;
						display: flex;
						align-items: center;
						margin-right: 40px;
						margin-top: 40px;
		
						.dc_cd_ld_di_img {
							// width: 80px;
							// height: 80px;
						}
		
						.dc_cd_ld_di_contentList {
							color: #333333;
							font-size: 14px;
							padding-left: 18px;
							display: table-cell;
							vertical-align: middle;
		
							.dc_cd_ld_di_cl_div {
								margin-top: 10px;
								display: flex;
		
								.text {
									display: inline-block;
									width: 80px;
								}
		
								.text2 {
									display: inline-block;
									width: 150px;
								}
		
								.label {
									display: inline-block;
									color: #d1102d;
									border-radius: 10px;
									border: 1px solid #d1102d;
									font-size: 12px;
									width: 58px;
									text-align: center;
									height: 18px;
									line-height: 18px;
								}
							}
						}
					}
				}
			}
			
			.dc_jump {
				display: inline-block;
				text-align: center;
				width: 260px;
				height: 46px;
				line-height: 46px;
				color: #ffffff;
				font-size: 16px;
				background-color: #f54e1b;
				border-radius: 30px;
				z-index: 3;
			}
		
			.dc_bottomDiv {
				padding: 80px 30px;
				background: url(../../../../assets/img/ability-bg.png);
				background-size: cover;
				width: 100%;
				text-align: center;
				color: #fff;
				line-height: 30px;
				font-size: 14px;
				position: relative;
		
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.designCapacity2 {
			padding: 40px 30px;
		
			.dc_titleDiv {
				width: 100%;
				text-align: center;
		
				.dc_td_title {
					font-size: 21px;
				}
		
				.dc_td_text {
					color: #666666;
					font-size: 14px;
					margin-top: 10px;
				}
			}
		
			.dc_contentDiv {
				padding-top: 36px;
				padding-bottom: 80px;
				position: relative;
		
				.dc_cd_img {
					z-index: 3;
					width: 100%;
					height: 300px;
				}
		
				.dc_cd_labelDiv {
					width: 100%;
					margin-top: 34px;
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
		
					.dc_cd_ld_div {
						width: 270px;
						display: flex;
						align-items: center;
						margin-right: 40px;
						margin-top: 40px;
		
						.dc_cd_ld_di_img {
							// width: 80px;
							// height: 80px;
						}
		
						.dc_cd_ld_di_contentList {
							color: #333333;
							font-size: 14px;
							padding-left: 18px;
							display: table-cell;
							vertical-align: middle;
		
							.dc_cd_ld_di_cl_div {
								margin-top: 10px;
								display: flex;
		
								.text {
									display: inline-block;
									width: 80px;
								}
		
								.text2 {
									display: inline-block;
									width: 150px;
								}
		
								.label {
									display: inline-block;
									color: #d1102d;
									border-radius: 10px;
									border: 1px solid #d1102d;
									font-size: 12px;
									width: 58px;
									text-align: center;
									height: 18px;
									line-height: 18px;
								}
							}
						}
					}
				}
			}
			
			.dc_jump {
				display: inline-block;
				text-align: center;
				width: 260px;
				height: 46px;
				line-height: 46px;
				color: #ffffff;
				font-size: 16px;
				background-color: #f54e1b;
				border-radius: 30px;
				z-index: 3;
			}
		
			.dc_bottomDiv {
				padding: 80px 30px;
				background: url(../../../../assets/img/ability-bg.png);
				background-size: cover;
				width: 100%;
				text-align: center;
				color: #fff;
				line-height: 30px;
				font-size: 14px;
				position: relative;
		
			}
		}
	}
	
</style>
