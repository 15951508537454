<template>
	<div>
		<div v-if="windowWidth>=1200">
			<div class="designProcessTitle">
				<div class="dpt_title">{{$t('solution.layoutDesign.designProcess.title')}}</div>
			</div>
			<div class="designProcessLabel">
				<div style="display: flex;align-items: center;">
					<div style="display: flex;">
						<img src="../../../../assets/img/icon/step1.png" />
						<div class="dpl_text">
							{{$t('solution.layoutDesign.designProcess.designProcessTitle1.text1')}}
							<br/>
							{{$t('solution.layoutDesign.designProcess.designProcessTitle1.text2')}}
						</div>
					</div>
					<img style="padding: 0 20px;" src="../../../../assets/img/icon/connection.png" />
					<div style="display: flex;">
						<img src="../../../../assets/img/icon/step2.png" />
						<div class="dpl_text">
							{{$t('solution.layoutDesign.designProcess.designProcessTitle2.text1')}}
							<br/>
							{{$t('solution.layoutDesign.designProcess.designProcessTitle2.text2')}}
						</div>
					</div>
					<img style="padding: 0 20px;" src="../../../../assets/img/icon/connection.png" />
					<div style="display: flex;">
						<img src="../../../../assets/img/icon/step3.png" />
						<div class="dpl_text">
							{{$t('solution.layoutDesign.designProcess.designProcessTitle3.text1')}}
							<br/>
							{{$t('solution.layoutDesign.designProcess.designProcessTitle3.text2')}}
						</div>
					</div>
					<img style="padding: 0 20px;" src="../../../../assets/img/icon/connection.png" />
					<div style="display: flex;">
						<img src="../../../../assets/img/icon/step4.png" />
						<div class="dpl_text">
							{{$t('solution.layoutDesign.designProcess.designProcessTitle4.text1')}}
							<br/>
							{{$t('solution.layoutDesign.designProcess.designProcessTitle4.text2')}}
						</div>
					</div>
					<img style="padding: 0 20px;" src="../../../../assets/img/icon/connection.png" />
					<div style="display: flex;">
						<img src="../../../../assets/img/icon/step5.png" />
						<div class="dpl_text">
							{{$t('solution.layoutDesign.designProcess.designProcessTitle5.text1')}}
							<br/>
							{{$t('solution.layoutDesign.designProcess.designProcessTitle5.text2')}}
						</div>
					</div>
					<img style="padding: 0 20px;" src="../../../../assets/img/icon/connection.png" />
					<div style="display: flex;">
						<img src="../../../../assets/img/icon/step6.png" />
						<div class="dpl_text">
							{{$t('solution.layoutDesign.designProcess.designProcessTitle6.text1')}}
							<br/>
							{{$t('solution.layoutDesign.designProcess.designProcessTitle6.text2')}}
						</div>
					</div>
					<img style="padding: 0 20px;" src="../../../../assets/img/icon/connection.png" />
					<div style="display: flex;">
						<img src="../../../../assets/img/icon/step7.png" />
						<div class="dpl_text">
							{{$t('solution.layoutDesign.designProcess.designProcessTitle7.text1')}}
							<br/>
							{{$t('solution.layoutDesign.designProcess.designProcessTitle7.text2')}}
						</div>
					</div>
					<img style="padding: 0 20px;" src="../../../../assets/img/icon/connection.png" />
					<div style="display: flex;">
						<img src="../../../../assets/img/icon/step8.png" />
						<div class="dpl_text">
							{{$t('solution.layoutDesign.designProcess.designProcessTitle8.text1')}}
							<br/>
							{{$t('solution.layoutDesign.designProcess.designProcessTitle8.text2')}}
						</div>
					</div>
				</div>
			</div>
			<div class="designProcessContent">
				<div class="dpc_imgText">
					<img style="width: 100%;" :src="$t('solution.layoutDesign.designProcess.designProcessContent1.img')" />
					<div class="dpc_it_content">
						<div class="dpc_it_ct_name">{{$t('solution.layoutDesign.designProcess.designProcessContent1.title')}}</div>
						<div class="dpc_it_ct_text">{{$t('solution.layoutDesign.designProcess.designProcessContent1.text')}}</div>
					</div>
				</div>
				
				<div class="dpc_imgText">
					<img style="width: 100%;" :src="$t('solution.layoutDesign.designProcess.designProcessContent2.img')" />
					<div class="dpc_it_content">
						<div class="dpc_it_ct_name">{{$t('solution.layoutDesign.designProcess.designProcessContent2.title')}}</div>
						<div class="dpc_it_ct_text">{{$t('solution.layoutDesign.designProcess.designProcessContent2.text')}}</div>
					</div>
				</div>
				
				<div class="dpc_imgText">
					<img style="width: 100%;" :src="$t('solution.layoutDesign.designProcess.designProcessContent3.img')" />
					<div class="dpc_it_content">
						<div class="dpc_it_ct_name">{{$t('solution.layoutDesign.designProcess.designProcessContent3.title')}}</div>
						<div class="dpc_it_ct_text">{{$t('solution.layoutDesign.designProcess.designProcessContent3.text')}}</div>
					</div>
				</div>
				
				<div class="dpc_imgText">
					<img style="width: 100%;" :src="$t('solution.layoutDesign.designProcess.designProcessContent4.img')" />
					<div class="dpc_it_content">
						<div class="dpc_it_ct_name">{{$t('solution.layoutDesign.designProcess.designProcessContent4.title')}}</div>
						<div class="dpc_it_ct_text">{{$t('solution.layoutDesign.designProcess.designProcessContent4.text')}}</div>
					</div>
				</div>
			</div>
		</div>
		
		<div v-if="windowWidth<=1199">
			<div class="designProcessTitle2">
				<div class="dpt_title">{{$t('solution.layoutDesign.designProcess.title')}}</div>
			</div>
			<div class="designProcessLabel">
				<div style="display: flex;align-items: center;flex-wrap: wrap;justify-content: center;">
					<div style="display: flex;padding: 20px 0;">
						<img src="../../../../assets/img/icon/step1.png" />
						<div class="dpl_text">
							{{$t('solution.layoutDesign.designProcess.designProcessTitle1.text1')}}
							<br/>
							{{$t('solution.layoutDesign.designProcess.designProcessTitle1.text2')}}
						</div>
					</div>
					<img style="padding: 0 20px;" src="../../../../assets/img/icon/connection.png" />
					<div style="display: flex;padding: 20px 0;">
						<img src="../../../../assets/img/icon/step2.png" />
						<div class="dpl_text">
							{{$t('solution.layoutDesign.designProcess.designProcessTitle2.text1')}}
							<br/>
							{{$t('solution.layoutDesign.designProcess.designProcessTitle2.text2')}}
						</div>
					</div>
					<img style="padding: 0 20px;" src="../../../../assets/img/icon/connection.png" />
					<div style="display: flex;padding: 20px 0;">
						<img src="../../../../assets/img/icon/step3.png" />
						<div class="dpl_text">
							{{$t('solution.layoutDesign.designProcess.designProcessTitle3.text1')}}
							<br/>
							{{$t('solution.layoutDesign.designProcess.designProcessTitle3.text2')}}
						</div>
					</div>
					<img style="padding: 0 20px;" src="../../../../assets/img/icon/connection.png" />
					<div style="display: flex;padding: 20px 0;">
						<img src="../../../../assets/img/icon/step4.png" />
						<div class="dpl_text">
							{{$t('solution.layoutDesign.designProcess.designProcessTitle4.text1')}}
							<br/>
							{{$t('solution.layoutDesign.designProcess.designProcessTitle4.text2')}}
						</div>
					</div>
					<img style="padding: 0 20px;" src="../../../../assets/img/icon/connection.png" />
					<div style="display: flex;padding: 20px 0;">
						<img src="../../../../assets/img/icon/step5.png" />
						<div class="dpl_text">
							{{$t('solution.layoutDesign.designProcess.designProcessTitle5.text1')}}
							<br/>
							{{$t('solution.layoutDesign.designProcess.designProcessTitle5.text2')}}
						</div>
					</div>
					<img style="padding: 0 20px;" src="../../../../assets/img/icon/connection.png" />
					<div style="display: flex;padding: 20px 0;">
						<img src="../../../../assets/img/icon/step6.png" />
						<div class="dpl_text">
							{{$t('solution.layoutDesign.designProcess.designProcessTitle6.text1')}}
							<br/>
							{{$t('solution.layoutDesign.designProcess.designProcessTitle6.text2')}}
						</div>
					</div>
					<img style="padding: 0 20px;" src="../../../../assets/img/icon/connection.png" />
					<div style="display: flex;padding: 20px 0;">
						<img src="../../../../assets/img/icon/step7.png" />
						<div class="dpl_text">
							{{$t('solution.layoutDesign.designProcess.designProcessTitle7.text1')}}
							<br/>
							{{$t('solution.layoutDesign.designProcess.designProcessTitle7.text2')}}
						</div>
					</div>
					<img style="padding: 0 20px;" src="../../../../assets/img/icon/connection.png" />
					<div style="display: flex;padding: 20px 0;">
						<img src="../../../../assets/img/icon/step8.png" />
						<div class="dpl_text">
							{{$t('solution.layoutDesign.designProcess.designProcessTitle8.text1')}}
							<br/>
							{{$t('solution.layoutDesign.designProcess.designProcessTitle8.text2')}}
						</div>
					</div>
				</div>
			</div>
			<div class="designProcessContent2">
				<div class="dpc_imgText">
					<img style="width: 100%;" :src="$t('solution.layoutDesign.designProcess.designProcessContent1.img')" />
					<div class="dpc_it_content">
						<div class="dpc_it_ct_name">{{$t('solution.layoutDesign.designProcess.designProcessContent1.title')}}</div>
						<div class="dpc_it_ct_text">{{$t('solution.layoutDesign.designProcess.designProcessContent1.text')}}</div>
					</div>
				</div>
				
				<div class="dpc_imgText">
					<img style="width: 100%;" :src="$t('solution.layoutDesign.designProcess.designProcessContent2.img')" />
					<div class="dpc_it_content">
						<div class="dpc_it_ct_name">{{$t('solution.layoutDesign.designProcess.designProcessContent2.title')}}</div>
						<div class="dpc_it_ct_text">{{$t('solution.layoutDesign.designProcess.designProcessContent2.text')}}</div>
					</div>
				</div>
				
				<div class="dpc_imgText">
					<img style="width: 100%;" :src="$t('solution.layoutDesign.designProcess.designProcessContent3.img')" />
					<div class="dpc_it_content">
						<div class="dpc_it_ct_name">{{$t('solution.layoutDesign.designProcess.designProcessContent3.title')}}</div>
						<div class="dpc_it_ct_text">{{$t('solution.layoutDesign.designProcess.designProcessContent3.text')}}</div>
					</div>
				</div>
				
				<div class="dpc_imgText">
					<img style="width: 100%;" :src="$t('solution.layoutDesign.designProcess.designProcessContent4.img')" />
					<div class="dpc_it_content">
						<div class="dpc_it_ct_name">{{$t('solution.layoutDesign.designProcess.designProcessContent4.title')}}</div>
						<div class="dpc_it_ct_text">{{$t('solution.layoutDesign.designProcess.designProcessContent4.text')}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.designProcessTitle{
			padding: 40px 200px;
			
			.dpt_title{
				font-size: 32px;
				color: #333333;
				text-align: center;
			}
		}
		
		.designProcessLabel{
			padding: 40px 200px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			
			.dpl_text{
				font-size: 12px;
				color: #222222;
				margin-left: 2px;
			}
		}
		
		.designProcessContent{
			padding: 0 200px;
			background: #222222;
			display: flex;
			width: 100%;
			position: relative;
			
			.dpc_imgText{
				width: 25%;
				float: left;
				background: #222222;
				position: relative;
				overflow: hidden;
				
				.dpc_it_content{
					position: absolute;
					top: 310px;
					z-index: 5;
					padding: 0 37px;
					color: #eee;
					
					.dpc_it_ct_name{
						font-size: 16px;
						font-weight: bold;
					}
					
					.dpc_it_ct_text{
						margin-top: 25px;
						line-height: 1.8;
						width: 100%;
						font-size: 14px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.designProcessTitle{
			padding: 40px 200px;
			
			.dpt_title{
				font-size: 32px;
				color: #333333;
				text-align: center;
			}
		}
		
		.designProcessLabel{
			padding: 40px 200px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			
			.dpl_text{
				font-size: 12px;
				color: #222222;
				margin-left: 2px;
			}
		}
		
		.designProcessContent{
			padding: 0 200px;
			background: #222222;
			display: flex;
			width: 100%;
			position: relative;
			
			.dpc_imgText{
				width: 25%;
				float: left;
				background: #222222;
				position: relative;
				overflow: hidden;
				
				.dpc_it_content{
					position: absolute;
					top: 310px;
					z-index: 5;
					padding: 0 37px;
					color: #eee;
					
					.dpc_it_ct_name{
						font-size: 16px;
						font-weight: bold;
					}
					
					.dpc_it_ct_text{
						margin-top: 25px;
						line-height: 1.8;
						width: 100%;
						font-size: 14px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.designProcessTitle{
			padding: 40px 140px;
			
			.dpt_title{
				font-size: 32px;
				color: #333333;
				text-align: center;
			}
		}
		
		.designProcessLabel{
			padding: 40px 140px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			
			.dpl_text{
				font-size: 12px;
				color: #222222;
				margin-left: 2px;
			}
		}
		
		.designProcessContent{
			padding: 0 140px;
			background: #222222;
			display: flex;
			width: 100%;
			position: relative;
			
			.dpc_imgText{
				width: 25%;
				float: left;
				background: #222222;
				position: relative;
				overflow: hidden;
				
				.dpc_it_content{
					position: absolute;
					top: 310px;
					z-index: 5;
					padding: 0 37px;
					color: #eee;
					
					.dpc_it_ct_name{
						font-size: 16px;
						font-weight: bold;
					}
					
					.dpc_it_ct_text{
						margin-top: 25px;
						line-height: 1.8;
						width: 100%;
						font-size: 14px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.designProcessTitle{
			padding: 40px 30px;
			
			.dpt_title{
				font-size: 32px;
				color: #333333;
				text-align: center;
			}
		}
		
		.designProcessLabel{
			padding: 40px 30px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			
			.dpl_text{
				font-size: 12px;
				color: #222222;
				margin-left: 2px;
			}
		}
		
		.designProcessContent{
			padding: 0 30px;
			background: #222222;
			display: flex;
			width: 100%;
			position: relative;
			
			.dpc_imgText{
				width: 25%;
				float: left;
				background: #222222;
				position: relative;
				overflow: hidden;
				
				.dpc_it_content{
					position: absolute;
					top: 310px;
					z-index: 5;
					padding: 0 37px;
					color: #eee;
					
					.dpc_it_ct_name{
						font-size: 16px;
						font-weight: bold;
					}
					
					.dpc_it_ct_text{
						margin-top: 25px;
						line-height: 1.8;
						width: 100%;
						font-size: 14px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.designProcessTitle2{
			padding: 40px 30px;
			
			.dpt_title{
				font-size: 21px;
				color: #333333;
				text-align: center;
			}
		}
		
		.designProcessLabel{
			padding: 40px 30px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			
			.dpl_text{
				font-size: 12px;
				color: #222222;
				margin-left: 2px;
			}
		}
		
		.designProcessContent2{
			padding: 0 30px;
			background: #222222;
			display: flex;
			width: 100%;
			position: relative;
			flex-wrap: wrap;
			
			.dpc_imgText{
				width: 49%;
				float: left;
				background: #222222;
				position: relative;
				padding-right: 1%;
				padding-bottom: 1%;
				overflow: hidden;
				
				.dpc_it_content{
					position: absolute;
					top: 310px;
					z-index: 5;
					padding: 0 37px;
					color: #eee;
					
					.dpc_it_ct_name{
						font-size: 16px;
						font-weight: bold;
					}
					
					.dpc_it_ct_text{
						margin-top: 25px;
						line-height: 1.8;
						width: 100%;
						font-size: 14px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.designProcessTitle2{
			padding: 0 30px 40px 30px;
			
			.dpt_title{
				font-size: 21px;
				color: #333333;
				text-align: center;
			}
		}
		
		.designProcessLabel{
			padding: 0 30px 40px 30px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			
			.dpl_text{
				font-size: 12px;
				color: #222222;
				margin-left: 2px;
			}
		}
		
		.designProcessContent2{
			padding: 0 30px;
			background: #222222;
			display: flex;
			width: 100%;
			position: relative;
			flex-wrap: wrap;
			
			.dpc_imgText{
				width: 100%;
				float: left;
				background: #222222;
				position: relative;
				padding-bottom: 2%;
				overflow: hidden;
				
				.dpc_it_content{
					position: absolute;
					bottom: 30px;
					z-index: 5;
					padding: 0 37px;
					color: #eee;
					
					.dpc_it_ct_name{
						font-size: 16px;
						font-weight: bold;
					}
					
					.dpc_it_ct_text{
						margin-top: 25px;
						line-height: 1.8;
						width: 100%;
						font-size: 14px;
					}
				}
			}
		}
	}
	
</style>