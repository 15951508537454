<template>
	<div>
		<div class="designCycle">
			<div class="dc_title">{{$t('solution.layoutDesign.designCycle.title')}}</div>
			
			<div class="tableContent">
				<table class="tc_table">
					<tbody class="tc_tb_tbody">
						<tr class="tc_tb_gaugeOutfit">
							<th class="tc_tb_go_th">{{$t('solution.layoutDesign.designCycle.text1')}}</th>
							<th class="tc_tb_go_th">{{$t('solution.layoutDesign.designCycle.text2')}}</th>
						</tr>
						<tr class="tc_tb_capability" v-for="(item,index) in $t('solution.layoutDesign.designCycle.tableList')" :key="index">
							<th class="tc_tb_ca_th" v-for="(item2,index2) in item.text" :key="index2">{{item2}}</th>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tableList:[{
					text:["1000以内","3-5天"]
				},
				{
					text:["2000-3000","5-7天"]
				},
				{
					text:["4000-5000","8-12天"]
				},
				{
					text:["6000-7000","12-15天"]
				},
				{
					text:["8000-9000","15-18天"]
				},
				{
					text:["10000-13000","18-20天"]
				},
				{
					text:["14000-15000","20-22天"]
				},
				{
					text:["16000-20000","22-30天"]
				}]
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.designCycle{
			padding: 40px 200px;
			
			.dc_title{
				text-align: center;
				font-size: 32px;
				color: #333333;
			}
			
			.tableContent{
				margin-top: 40px;
				background: #fff;
				
				.tc_table{
					width: 100%;
					font-size: 14px;
					
					.tc_tb_tbody{
						display: table-row-group;
						vertical-align: middle;
						border-color: inherit;
						
						.tc_tb_gaugeOutfit{
							background: #f3f3f3;
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
							
							.tc_tb_go_th{
								padding: 12px 0 12px 70px;
								background-color: #f3f3f3;
								text-align: left;
								font-size: 16px;
								width: 38%;
							}
						}
						
						.tc_tb_capability{
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
							
							.tc_tb_ca_th{
								padding: 13px 0 13px 70px;
								text-align: left;
								// border-top: 1px solid #e6e6e6;
							}
						}
						
						.tc_tb_capability:nth-of-type(2n+1){
							background-color: #f3f3f3;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.designCycle{
			padding: 40px 200px;
			
			.dc_title{
				text-align: center;
				font-size: 32px;
				color: #333333;
			}
			
			.tableContent{
				margin-top: 40px;
				background: #fff;
				
				.tc_table{
					width: 100%;
					font-size: 14px;
					
					.tc_tb_tbody{
						display: table-row-group;
						vertical-align: middle;
						border-color: inherit;
						
						.tc_tb_gaugeOutfit{
							background: #f3f3f3;
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
							
							.tc_tb_go_th{
								padding: 12px 0 12px 70px;
								background-color: #f3f3f3;
								text-align: left;
								font-size: 16px;
								width: 38%;
							}
						}
						
						.tc_tb_capability{
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
							
							.tc_tb_ca_th{
								padding: 13px 0 13px 70px;
								text-align: left;
								// border-top: 1px solid #e6e6e6;
							}
						}
						
						.tc_tb_capability:nth-of-type(2n+1){
							background-color: #f3f3f3;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.designCycle{
			padding: 40px 140px;
			
			.dc_title{
				text-align: center;
				font-size: 32px;
				color: #333333;
			}
			
			.tableContent{
				margin-top: 40px;
				background: #fff;
				
				.tc_table{
					width: 100%;
					font-size: 14px;
					
					.tc_tb_tbody{
						display: table-row-group;
						vertical-align: middle;
						border-color: inherit;
						
						.tc_tb_gaugeOutfit{
							background: #f3f3f3;
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
							
							.tc_tb_go_th{
								padding: 12px 0 12px 70px;
								background-color: #f3f3f3;
								text-align: left;
								font-size: 16px;
								width: 38%;
							}
						}
						
						.tc_tb_capability{
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
							
							.tc_tb_ca_th{
								padding: 13px 0 13px 70px;
								text-align: left;
								// border-top: 1px solid #e6e6e6;
							}
						}
						
						.tc_tb_capability:nth-of-type(2n+1){
							background-color: #f3f3f3;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.designCycle{
			padding: 40px 30px;
			
			.dc_title{
				text-align: center;
				font-size: 32px;
				color: #333333;
			}
			
			.tableContent{
				margin-top: 40px;
				background: #fff;
				
				.tc_table{
					width: 100%;
					font-size: 14px;
					
					.tc_tb_tbody{
						display: table-row-group;
						vertical-align: middle;
						border-color: inherit;
						
						.tc_tb_gaugeOutfit{
							background: #f3f3f3;
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
							
							.tc_tb_go_th{
								padding: 12px 0 12px 70px;
								background-color: #f3f3f3;
								text-align: left;
								font-size: 16px;
								width: 38%;
							}
						}
						
						.tc_tb_capability{
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
							
							.tc_tb_ca_th{
								padding: 13px 0 13px 70px;
								text-align: left;
								// border-top: 1px solid #e6e6e6;
							}
						}
						
						.tc_tb_capability:nth-of-type(2n+1){
							background-color: #f3f3f3;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.designCycle{
			padding: 40px 30px;
			
			.dc_title{
				text-align: center;
				font-size: 21px;
				color: #333333;
			}
			
			.tableContent{
				margin-top: 40px;
				background: #fff;
				
				.tc_table{
					width: 100%;
					font-size: 14px;
					
					.tc_tb_tbody{
						display: table-row-group;
						vertical-align: middle;
						border-color: inherit;
						
						.tc_tb_gaugeOutfit{
							background: #f3f3f3;
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
							
							.tc_tb_go_th{
								padding: 12px 0 12px 70px;
								background-color: #f3f3f3;
								text-align: left;
								font-size: 16px;
								width: 38%;
							}
						}
						
						.tc_tb_capability{
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
							
							.tc_tb_ca_th{
								padding: 13px 0 13px 70px;
								text-align: left;
								// border-top: 1px solid #e6e6e6;
							}
						}
						
						.tc_tb_capability:nth-of-type(2n+1){
							background-color: #f3f3f3;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.designCycle{
			padding: 40px 30px;
			
			.dc_title{
				text-align: center;
				font-size: 21px;
				color: #333333;
			}
			
			.tableContent{
				margin-top: 40px;
				background: #fff;
				
				.tc_table{
					width: 100%;
					font-size: 14px;
					
					.tc_tb_tbody{
						display: table-row-group;
						vertical-align: middle;
						border-color: inherit;
						
						.tc_tb_gaugeOutfit{
							background: #f3f3f3;
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
							
							.tc_tb_go_th{
								padding: 12px 0 12px 30px;
								background-color: #f3f3f3;
								text-align: left;
								font-size: 14px;
								width: 38%;
							}
						}
						
						.tc_tb_capability{
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
							
							.tc_tb_ca_th{
								padding: 13px 0 13px 30px;
								text-align: left;
								// border-top: 1px solid #e6e6e6;
							}
						}
						
						.tc_tb_capability:nth-of-type(2n+1){
							background-color: #f3f3f3;
						}
					}
				}
			}
		}
	}
	
</style>