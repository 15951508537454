<template>
	<div>
		<div class="designServices" v-if="windowWidth>=1200">
			<div class="ds_title">{{$t('solution.layoutDesign.designServices.title')}}</div>

			<el-carousel height="550px">
				<el-carousel-item v-for="(item,index) in $t('solution.layoutDesign.designServices.list')" :key="index">
					<div class="ds_carouselDiv">
						<div class="ds_cd_contentDiv" v-for="(item2,index2) in item.list" :key="index2">
							<div class="ds_cd_cd_div">
								<img class="ds_cd_cd_di_img" :src="item2.img" />
								<span class="ds_cd_cd_di_index">{{'0' + (index2+1)}}</span>
							</div>
							
							<div class="ds_cd_cd_content">{{item2.text}}</div>
						</div>
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>
		
		<div class="designServices2" v-if="windowWidth<=1199">
			<div class="ds_title">{{$t('solution.layoutDesign.designServices.title')}}</div>
		
			<el-carousel :height="windowWidth>=900 ? '900px' : '650px'">
				<el-carousel-item v-for="(item,index) in $t('solution.layoutDesign.designServices.list2')" :key="index">
					<div class="ds_carouselDiv">
						<div class="ds_cd_contentDiv" v-for="(item2,index2) in item.list" :key="index2">
							<div class="ds_cd_cd_div">
								<img class="ds_cd_cd_di_img" :src="item2.img" />
								<span class="ds_cd_cd_di_index">{{'0' + (index2+1)}}</span>
							</div>
							
							<div class="ds_cd_cd_content">{{item2.text}}</div>
						</div>
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
			}
		},

		created() {

		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {

		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.designServices{
			padding: 40px 200px;
			background: #f5f5f5;
			
			.ds_title{
				text-align: center;
				font-size: 36px;
				color: #333333;
			}
			
			.ds_carouselDiv{
				display: flex;
				width: 100%;
				padding-top: 40px;
				
				.ds_cd_contentDiv{
					width: 32%;
					margin-right: 1%;
					background: #fff;
					padding: 20px;
					
					.ds_cd_cd_div{
						position: relative;
						
						.ds_cd_cd_di_img{
							border: none;
							vertical-align: middle;
							width: 100%;
						}
						
						.ds_cd_cd_di_index{
							position: absolute;
							bottom: -12px;
							left: 49%;
							margin-left: -5px;
							z-index: 3;
							color: #d1102d;
							font-size: 16px;
						}
					}
					
					.ds_cd_cd_content{
						margin-top: 30px;
						font-size: 14px;
						color: #333333;
						overflow: hidden;
						height: 106px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.designServices{
			padding: 40px 200px;
			background: #f5f5f5;
			
			.ds_title{
				text-align: center;
				font-size: 36px;
				color: #333333;
			}
			
			.ds_carouselDiv{
				display: flex;
				width: 100%;
				padding-top: 40px;
				
				.ds_cd_contentDiv{
					width: 32%;
					margin-right: 1%;
					background: #fff;
					padding: 20px;
					
					.ds_cd_cd_div{
						position: relative;
						
						.ds_cd_cd_di_img{
							border: none;
							vertical-align: middle;
							width: 100%;
						}
						
						.ds_cd_cd_di_index{
							position: absolute;
							bottom: -12px;
							left: 49%;
							margin-left: -5px;
							z-index: 3;
							color: #d1102d;
							font-size: 16px;
						}
					}
					
					.ds_cd_cd_content{
						margin-top: 30px;
						font-size: 14px;
						color: #333333;
						overflow: hidden;
						height: 106px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.designServices{
			padding: 40px 140px;
			background: #f5f5f5;
			
			.ds_title{
				text-align: center;
				font-size: 36px;
				color: #333333;
			}
			
			.ds_carouselDiv{
				display: flex;
				width: 100%;
				padding-top: 40px;
				
				.ds_cd_contentDiv{
					width: 32%;
					margin-right: 1%;
					background: #fff;
					padding: 20px;
					
					.ds_cd_cd_div{
						position: relative;
						
						.ds_cd_cd_di_img{
							border: none;
							vertical-align: middle;
							width: 100%;
						}
						
						.ds_cd_cd_di_index{
							position: absolute;
							bottom: -12px;
							left: 49%;
							margin-left: -5px;
							z-index: 3;
							color: #d1102d;
							font-size: 16px;
						}
					}
					
					.ds_cd_cd_content{
						margin-top: 30px;
						font-size: 14px;
						color: #333333;
						overflow: hidden;
						height: 106px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.designServices{
			padding: 40px 30px;
			background: #f5f5f5;
			
			.ds_title{
				text-align: center;
				font-size: 36px;
				color: #333333;
			}
			
			.ds_carouselDiv{
				display: flex;
				width: 100%;
				padding-top: 40px;
				
				.ds_cd_contentDiv{
					width: 32%;
					margin-right: 1%;
					background: #fff;
					padding: 20px;
					
					.ds_cd_cd_div{
						position: relative;
						
						.ds_cd_cd_di_img{
							border: none;
							vertical-align: middle;
							width: 100%;
						}
						
						.ds_cd_cd_di_index{
							position: absolute;
							bottom: -12px;
							left: 49%;
							margin-left: -5px;
							z-index: 3;
							color: #d1102d;
							font-size: 16px;
						}
					}
					
					.ds_cd_cd_content{
						margin-top: 30px;
						font-size: 14px;
						color: #333333;
						overflow: hidden;
						height: 106px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.designServices2{
			padding: 40px 30px;
			background: #f5f5f5;
			
			.ds_title{
				text-align: center;
				font-size: 21px;
				color: #333333;
			}
			
			.ds_carouselDiv{
				width: 100%;
				padding-top: 40px;
				
				.ds_cd_contentDiv{
					width: 100%;
					margin-bottom: 20px;
					background: #fff;
					padding: 20px;
					
					.ds_cd_cd_div{
						position: relative;
						
						.ds_cd_cd_di_img{
							border: none;
							vertical-align: middle;
							width: 100%;
						}
						
						.ds_cd_cd_di_index{
							position: absolute;
							bottom: -12px;
							left: 49%;
							margin-left: -5px;
							z-index: 3;
							color: #d1102d;
							font-size: 16px;
						}
					}
					
					.ds_cd_cd_content{
						margin-top: 30px;
						font-size: 14px;
						color: #333333;
						overflow: hidden;
						height: 106px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.designServices2{
			padding: 40px 30px;
			background: #f5f5f5;
			
			.ds_title{
				text-align: center;
				font-size: 21px;
				color: #333333;
			}
			
			.ds_carouselDiv{
				width: 100%;
				padding-top: 40px;
				
				.ds_cd_contentDiv{
					width: 100%;
					margin-bottom: 20px;
					background: #fff;
					padding: 20px;
					
					.ds_cd_cd_div{
						position: relative;
						
						.ds_cd_cd_di_img{
							border: none;
							vertical-align: middle;
							width: 100%;
						}
						
						.ds_cd_cd_di_index{
							position: absolute;
							bottom: -12px;
							left: 49%;
							margin-left: -5px;
							z-index: 3;
							color: #d1102d;
							font-size: 16px;
						}
					}
					
					.ds_cd_cd_content{
						margin-top: 30px;
						font-size: 14px;
						color: #333333;
						overflow: hidden;
						height: 106px;
					}
				}
			}
		}
	}
	
</style>
